import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kominík Michal Kožíšek - SlužbyX', // e.g: 'Name | Developer'
  lang: 'cz', // e.g: en, es, fr, jp
  description:
    'Kominictví plzeňsko - Michal Kožíšek, kominík pro Plzeň a okolí. Poskytujeme kompletní kominický servis, opravy a vložkování komínů. Provádímě kontrolu a čištění spalinových cest pro všechny druhy paliva.', // e.g: Welcome to my website
  keywords:
    'Kominictví Plzeň, kominictvi plzen, kominik plzen-jih, kominik plzen, kominictvi plzensko, kominík plzeň, kominík klatovy, kominík rokycany, komenictvi plzen, kominictví kožíšek, kominictvi kozisek, Michal Kožíšek kominík, Kominictví Kožíšek, kominické služby, revize komínů, Komeník Plzeň, Kominík, Komenictví plzensko',
};

// HERO DATA
export const heroData = {
  title: 'Kominictví Plzeňsko',
  name: ' ',
  subtitle: 'kominické služby na plzeňsku',
  cta: 'Co Nabízíme',
};

// ABOUT DATA
export const aboutData = {
  img: 'chimney2.jpg',
  imgDownload: 'download.png',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// FOOTER DATA
export const kontaktData = {
  img: 'RegionNew.png',
  defaultCenter: {
    lng: '49.7384',
    lat: '13.3736',
  },
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
